import React, { createContext, useReducer } from 'react';
//

const initialState = {
  displayYoutubeOverlay: false,
  currentYoutubeOverlayVideoID: null,
  homeActiveIndex: 0,
};

const Store = createContext(initialState);
const { Provider } = Store;

const STORE_CONSTANTS = {
  ACTIONS: {
    SET_DISPLAY_YOUTUBE_OVERLAY: 'set-display-youtube-overlay',
    SET_CURRENT_YOUTUBE_OVERLAY_VIDEO_ID:
      'set-current-youtube-overlay-video-id',
    SET_HOME_ACTIVE_INDEX: 'set-home-active-index',
  },
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    const { type, payload } = action;
    const newState = { ...state };

    switch (type) {
      case STORE_CONSTANTS.ACTIONS.SET_DISPLAY_YOUTUBE_OVERLAY:
        newState.displayYoutubeOverlay = payload;

        return newState;

      case STORE_CONSTANTS.ACTIONS.SET_CURRENT_YOUTUBE_OVERLAY_VIDEO_ID:
        newState.currentYoutubeOverlayVideoID = payload;

        return newState;

      case STORE_CONSTANTS.ACTIONS.SET_HOME_ACTIVE_INDEX:
        newState.homeActiveIndex = payload;

        return newState;

      default:
        break;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { STORE_CONSTANTS, Store, StateProvider };
