import React from 'react';
import _ from 'lodash';
//
import './Footer.scss';

export default function Footer({ footerData }) {
  const parseFields = () => {
    return {
      legalText: _.get(
        footerData,
        'legalText.fields.text',
        'LEGAL TEXT MISSING'
      ),
      items: _.get(footerData, 'items', []),
    };
  };

  const { legalText, items } = parseFields();

  const renderItems = () => {
    return items.map((item, index) => {
      const { text, labelText, link, opensNewWindow } = item.fields;

      if (text) {
        return (
          <li key={index} className='item'>
            {text}
          </li>
        );
      } else {
        return (
          <li key={index} className='item'>
            <a
              href={link}
              target={opensNewWindow ? '_blank' : '_self'}
              rel={opensNewWindow ? 'noopener noreferrer' : ''}
            >
              {labelText}
            </a>
          </li>
        );
      }
    });
  };

  return (
    <div className='Footer'>
      <div className='legal-text'>{legalText}</div>
      <ul className='items'>{renderItems()}</ul>
    </div>
  );
}
