import React, { useState } from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';
//
import './TopNav.scss';

export default function TopNav({ topNavData }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuDisplay, setmenuDisplay] = useState(false);

  const parseFields = () => {
    return {
      logoImage: _.get(
        topNavData,
        'logoImage.fields.image.fields.file.url',
        '/img/PLACEHOLDER.jpg'
      ),
      logoImageAltText: _.get(
        topNavData,
        'logoImage.fields.altText',
        'LOGO IMAGE ALT TEXT MISSING'
      ),
      links: _.get(topNavData, 'links', []),
    };
  };

  const { logoImage, logoImageAltText, links } = parseFields();

  const renderLinks = () => {
    let navLinks = [];

    navLinks = links.map((item, index) => {
      const { labelText, link, opensNewWindow } = item.fields;

      return (
        <li key={index} className='link'>
          <Link
            to={link}
            target={opensNewWindow ? '_blank' : '_self'}
            rel={opensNewWindow ? 'noopener noreferrer' : ''}
          >
            {labelText}
          </Link>
        </li>
      );
    });

    navLinks.unshift(
      <li key='home' className='link home-link'>
        <Link to='/'>Home</Link>
      </li>
    );

    return navLinks;
  };

  const handleClickMenu = () => {
    setMenuOpen(!menuOpen);

    if (!menuOpen) {
      setTimeout(() => {
        setmenuDisplay(true);
      }, 180);
    } else {
      setmenuDisplay(false);
    }
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      handleClickMenu();
    }
  };

  return (
    <nav className='TopNav' role='navigation'>
      <div className='nav-bar'>
        <div className='logo-container'>
          <Link className='logo' to='/'>
            <img src={logoImage} alt={logoImageAltText} />
          </Link>
        </div>
        <ul className='nav-links'>{renderLinks()}</ul>
        <div
          className='hamburger'
          role='button'
          tabIndex={0}
          aria-label={menuOpen ? 'Close menu' : 'Open menu'}
          onClick={handleClickMenu}
          onKeyDown={e => handleKeyDown(e)}
        >
          <svg width='18' height='14.5'>
            <rect width='18' height='1.1' fill='#000'></rect>
            <rect y='6.7' width='18' height='1.1' fill='#000'></rect>
            <rect y='13.4' width='18' height='1.1' fill='#000'></rect>
          </svg>
        </div>
      </div>
      <div className={`menu-container ${menuOpen ? 'slideIn' : ''}`}>
        <ul className={`menu-content text-XLarge ${menuDisplay ? 'show' : ''}`}>
          {renderLinks()}
        </ul>
      </div>
    </nav>
  );
}
