import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';
//
import './../../scss/index.scss';

export default function Head({
  title = 'TITLE MISSING',
  description = 'DESCRIPTION MISSING',
  keywords = 'KEYWORDS MISSING',
  image,
  url = 'URL MISSING',
}) {
  useEffect(() => {
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    typeof window !== 'undefined' &&
      window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const getImage = () => {
    return _.get(image, 'fields.file.url', 'IMAGE MISSING');
  };

  return (
    <Helmet>
      <link rel='shortcut icon' href={`favicon.ico`} />

      <title>{title}</title>

      <link rel='canonical' href={url} />
      <meta property='og:url' content={url} />

      <meta itemprop='name' content={title} />
      <meta name='description' content={description} />
      <meta itemprop='description' content={description} />
      <meta name='image' content={getImage()} />

      <meta name='keywords' content={keywords} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image:src' content={getImage()} />
      <meta name='twitter:site' content='@Verizon' />

      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={getImage()} />

      <meta property='og:type' content='website' />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />

      <link rel='stylesheet' type='text/css' href={`/fonts/fonts.css`} />

      {/* Google Tag Manager */}
      <script type='text/javascript' src={`/js/scripts.js`}></script>
      {/* End Google Tag Manager */}
    </Helmet>
  );
}

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.object,
  url: PropTypes.string,
};
