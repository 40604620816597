import React, { useContext, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import FocusTrap from 'focus-trap-react';
//
import './YoutubeOverlay.scss';
import { STORE_CONSTANTS, Store } from '../../Store';
import YoutubePlayer from '../youtubePlayer/YoutubePlayer';

export default function YoutubeOverlay() {
  const { state: globalState, dispatch } = useContext(Store);

  const [focusTrapActive, setFocusTrapActive] = useState(false);

  const containerRef = useRef();
  const closeButtonRef = useRef();
  const playerRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyUp = event => {
    if (event.key === 'Escape' && globalState.displayYoutubeOverlay) {
      handleClose();
    }
  };

  useEffect(() => {
    if (globalState.displayYoutubeOverlay) {
      gsap.to(containerRef.current, {
        duration: 0.25,
        ease: 'linear',
        display: 'flex',
        opacity: 1,
      });

      document.addEventListener('keyup', handleKeyUp);

      document.body.classList.add('no-scroll');

      setTimeout(() => {
        playerRef.current.playVideo();

        setFocusTrapActive(true);
      }, 1000);
    } else if (!globalState.displayYoutubeOverlay) {
      setFocusTrapActive(false);

      gsap.to(containerRef.current, {
        duration: 0.25,
        ease: 'linear',
        opacity: 0,
        display: 'none',
      });

      document.removeEventListener('keyup', handleKeyUp);

      document.body.classList.remove('no-scroll');
    }
  }, [globalState.displayYoutubeOverlay, handleKeyUp, setFocusTrapActive]);

  const handleClose = () => {
    playerRef.current.pauseVideo();

    dispatch({
      type: STORE_CONSTANTS.ACTIONS.SET_DISPLAY_YOUTUBE_OVERLAY,
      payload: false,
    });

    setTimeout(() => {
      dispatch({
        type: STORE_CONSTANTS.ACTIONS.SET_CURRENT_YOUTUBE_OVERLAY_VIDEO_ID,
        payload: null,
      });
    }, 250);
  };

  const handleKeyDownClose = event => {
    if (event.keyCode === 13) {
      handleClose();
    }
  };

  return (
    <FocusTrap active={focusTrapActive}>
      <div
        className={`YoutubeOverlay`}
        ref={containerRef}
        role='dialog'
        aria-modal={true}
      >
        <div
          className='close-button'
          onClick={handleClose}
          onKeyDown={e => handleKeyDownClose(e)}
          role='button'
          tabIndex='0'
          ref={closeButtonRef}
        >
          <img src='/img/close-wh.png' alt='close' />
        </div>
        <div className='video-container'>
          {globalState.currentYoutubeOverlayVideoID && (
            <YoutubePlayer
              ref={playerRef}
              videoID={globalState.currentYoutubeOverlayVideoID}
            />
          )}
        </div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          className='overlay-background'
          tabIndex={0}
          role='button'
          onKeyDown={e => handleKeyDownClose(e)}
        ></div>
      </div>
    </FocusTrap>
  );
}
