import React, { useEffect } from 'react';
//
import Utils from './../Utils';

export default function ThemeWrapper({ children, pageStyle }) {
  const initialBackgroundColor = '1B1D1F';
  const finalBackgroundColor = 'FFFFFF';
  const finalToutBackgroundColor = 'f6f6f6';
  const initialTextColor = 'FFFFFF';
  const finalTextColor = '1B1D1F';

  useEffect(() => {
    init();
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined')
        window.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = () => {
    if (pageStyle === 'home') {
      let progress = 0;
      let interval = setInterval(() => {
        progress += 0.12;

        document.body.style.backgroundColor = `#${Utils.interpolateColor(
          finalBackgroundColor,
          initialBackgroundColor,
          Math.min(progress, 1)
        )}`;
        document.querySelectorAll('.Tout').forEach(item => {
          item.style.backgroundColor = `#${Utils.interpolateColor(
            finalToutBackgroundColor,
            initialBackgroundColor,
            Math.min(progress, 1)
          )}`;
        });
        document.body.style.color = `#${Utils.interpolateColor(
          finalTextColor,
          initialTextColor,
          Math.min(progress, 1)
        )}`;

        if (progress > 1) clearInterval(interval);
      }, 30);
    } else {
      document.body.style.backgroundColor = `#${initialBackgroundColor}`;
      document.querySelectorAll('.Tout').forEach(item => {
        item.style.backgroundColor = `#${initialBackgroundColor}`;
      });
      document.body.style.color = `#${initialTextColor}`;
    }
  };

  const handleScroll = () => {
    const scrolledPercentage = Math.min(window.scrollY / 350, 1);

    document.body.style.backgroundColor = `#${Utils.interpolateColor(
      initialBackgroundColor,
      finalBackgroundColor,
      scrolledPercentage
    )}`;
    document.querySelectorAll('.Tout').forEach(item => {
      item.style.backgroundColor = `#${Utils.interpolateColor(
        initialBackgroundColor,
        finalToutBackgroundColor,
        scrolledPercentage
      )}`;
    });
    document.body.style.color = `#${Utils.interpolateColor(
      initialTextColor,
      finalTextColor,
      scrolledPercentage
    )}`;
  };

  return <div className='theme-wrapper'>{children}</div>;
}
