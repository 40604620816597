import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
//

import './YoutubePlayer.scss';

function YoutubePlayer({ videoID, handleVideoEnded }, ref) {
  const playerContainerRef = useRef();
  let timeout, player;

  useEffect(() => {
    checkYoutubeObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      playVideo: () => {
        player.playVideo();
      },
      pauseVideo: () => {
        player.pauseVideo();
      },
      resetVideo: () => {
        player.seekTo(0);
      },
    }),
    [player]
  );

  const checkYoutubeObject = () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      // eslint-disable-next-line no-undef
      if (YT) {
        // eslint-disable-next-line no-undef
        if (YT.Player) {
          initVideo();

          return;
        }
      }

      checkYoutubeObject();
    }, 100);
  };

  const initVideo = () => {
    // eslint-disable-next-line no-undef
    player = new YT.Player(playerContainerRef.current, {
      videoId: videoID,
      events: {
        onStateChange: handlePlayerStateChange,
      },
    });
  };

  const handlePlayerStateChange = event => {
    // eslint-disable-next-line no-undef
    if (event.data === YT.PlayerState.ENDED) {
      handleVideoEnded && handleVideoEnded();
    }
  };

  return (
    <div className='YoutubePlayer'>
      <div className='player-container' ref={playerContainerRef} />
    </div>
  );
}

export default forwardRef(YoutubePlayer);
