export default {
  interpolateColor: (color1, color2, progress) => {
    // Progress is 0 to 1
    color1 = color1
      .match(/.{1,2}/g)
      .map(oct => parseInt(oct, 16) * (1 - progress));
    color2 = color2.match(/.{1,2}/g).map(oct => parseInt(oct, 16) * progress);
    let ci = [0, 1, 2].map(i =>
      Math.min(Math.round(color1[i] + color2[i]), 255)
    );
    return ci
      .reduce((a, v) => (a << 8) + v, 0)
      .toString(16)
      .padStart(6, '0');
  },
};
